.title.title {
  margin: 0 0 16px;
}

.title.title:not(:first-child) {
  margin-top: 24px;
}
.icon.icon {
  width: 18px;
  height: 18px;
}

.iconRotated {
  transform: rotate(90deg);
}

.menuItem.menuItem {
  transition: background 0.3s;
}

.menuItemActive.menuItemActive.menuItemActive {
  background: var(--primary-color-alpha-20);
  font-weight: 600;
}

.disabledSlider {
  opacity: 0.2;
}
