.title.title {
  margin: 0 0 16px;
}

.select {
  display: block;
}

.modelOption {
  display: flex;
  justify-content: space-between;
}

.modelName.modelName {
  margin: 0;
}

.modelSize {
  margin: 0;
}

.menuItem.menuItem {
  transition: background 0.3s;
}

.menuItemActive.menuItemActive.menuItemActive {
  background: var(--primary-color-alpha-20);
  font-weight: 600;
}

@media (min-width: 768px) {
  .additionalLabel {
    display: none;
  }
}
