.wrapper {
  display: none;
}

@media (min-width: 1024px) {
  .wrapper {
    display: block;
    width: 160px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    > div {
      height: 100%;
    }
  }
}
