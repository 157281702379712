.wrapper {
  display: grid;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;

  button {
    background: transparent !important;
  }
}

.input {
  display: none;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 24px;
}
