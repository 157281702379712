.wrapper {
  height: 100%;
  display: grid;
  align-items: center;
  grid-gap: 16px;
  padding: 0 var(--padding);
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.extra {
  margin-right: -12px;
  margin-left: auto;
  display: flex;
}

.shareIcon {
  position: relative;
  bottom: 1px;
}

.icon.icon {
  color: var(--primary-color);
}

@media (min-width: 768px) {
  .image {
    left: 0;
  }

  .wrapper {

    padding: 0;
    grid-template-columns: 1fr;
  }
}
