.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  canvas {
    border-radius: var(--border-radius);
    pointer-events: none;
  }
}
