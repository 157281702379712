.wrapper {
  position: relative;
  display: grid;
  align-content: stretch;
  grid-gap: var(--padding-small);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  overflow-y: scroll;
  overscroll-behavior-y: none;
  height: 100%;
  grid-template-rows:
    calc(100% - var(--padding-small) - var(--padding))
    auto;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.disableSelection * {
  user-select: none;
}

.imageBack {
  position: sticky;
  top: 0;
  padding-bottom: 0;
  height: 100%;
}

.image {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: calc(100% - 84px);
}

.buttons {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  padding-top: var(--padding-small);
}

.interactionsBlock {
  min-width: 285px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  position: sticky;
  top: 0;
  padding: 24px;
  background: var(--background-secondary-color);
  pointer-events: all;
  border-radius: var(--border-radius) var(--border-radius) 0 0;

  &::before {
    content: '';
    position: absolute;
    width: 36px;
    height: 4px;
    border-radius: 2px;
    background: var(--chakra-colors-whiteAlpha-300);
    margin: auto;
    left: 0;
    right: 0;
    top: calc(var(--padding) / 2 - 2px);
  }
}

@media (min-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr auto;
    grid-template-rows: unset;
    grid-gap: var(--padding);
    align-content: stretch;
    padding: var(--padding);
    padding-bottom: 24px;
  }

  .image {
    height: 100%;
  }

  .interactionsWrapper {
    position: static;
    height: auto;
  }

  .imageGap {
    display: none;
  }

  .interactionsBlock {
    border-radius: 8px;
    overflow: auto;
    max-height: 100%;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &::before {
      content: none;
    }
  }

  .buttons {
    position: static;
    margin-top: auto;
    height: unset;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    grid-template-columns: auto 1fr auto;
  }
}